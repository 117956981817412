const config = { 
    BASE_URL : "https://server.developer.bicads.com/",
    // BASE_URL : "http://localhost:3001/",
SITE_NAME : 'Lord Petroleum',
contact1:'+97142264420',
contact2:'9028046401',
email_id:'info@lordpetroleum.com',
ImageUrl:'assets/images/petrol/',
address:'PO box 416651, Dubai, UAE',

 
     
}
export default config;